import React, { useEffect, useState } from "react";
import "./UserContent.css";
import loaderImg from "../Images/loader.gif";
import axios from "axios";
import { API } from "../Config/config";
export default function UserContent() {
  const [selected, setSelected] = useState("scheduled");
  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState([]);

  
  const getContent = () => {
    axios.get(`${API.apiUrl}/schedulecontent/${selected}`).then((res) => {
      console.log(res);
      setContent(res.data);
    }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(()=>{
    getContent();
  }, [])
 

  return (
    <div>
      <div className="content-library-container">
        {/* Content Library Header */}
        <div className="library-header">
          <h2>My Library</h2>
          
        </div>

        <div className="toggler">
          <div className="inner-toggle">
            <span
              className={`${selected === "scheduled" ? "selected" : null}`}
              onClick={() => {
                setSelected("scheduled");
              }}
            >
              {" "}
              Scheduled Content{" "}
            </span>
            <span
              className={`${selected === "posted" ? "selected" : null}`}
              onClick={() => {
                setSelected("posted");
              }}
            >
              {" "}
              Posted Content{" "}
            </span>
          </div>
        </div>

        {selected === "images" ? (
          <>
            {/* Content Grid */}
            <div className="content-grid">
              {loader ? (
                <>
                  <center>
                    <img src={loaderImg} alt="loader" width={200} />
                  </center>
                </>
              ) : (
                <>
                <div>
                  {content.map((item) => {
                    return(
                      <div>
                        
                      </div>
                    )
                  })}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Content Grid */}
            <div className="content-grid">
              {loader ? (
                <>
                  <center>
                    <img src={loaderImg} alt="loader" width={200} />
                  </center>
                </>
              ) : (
                <>
                  
                </>
              )}
            </div>

            
          </>
        )}
      </div>
    </div>
  );
}
