import React, { useState } from 'react';
import './Facebook.css';
import FacebookLoginComponent from './FaceBookLogin';


const Facebook = () => {
  
  return (
    <div className="facebook-container">
      <h2>Facebook API Integration</h2>
      <FacebookLoginComponent/>
    </div>
  );
};

export default Facebook;
