import React, { useState } from "react";
import "./instagram.css";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import axios from "axios";

const Instagram = () => {
  const responseInstagram = (response) => {
    console.log(response);
  };

  const login = () => {
    axios
      .post(`https://api.instagram.com/oauth/access_token`, {
        client_id: "876516320855801",
        client_secret: "28197a061d6173c9fb06075cf9d4b134",
        grant_type: "authorization_code",
        redirect_uri: "https://localhost:3000/"
      })
      .then((response) => {
        console.log(response.data.access_token); // Use the token to access user data
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="instagram-container">
      <h2>Instagram API Integration</h2>
      <button onClick={login}>Login from insta</button>
    </div>
  );
};

export default Instagram;
